const QUERIES = {
  USERS_LIST: 'users-list',
  DRIVERS_LIST: 'drivers-list',
  ORDERS_LIST: 'orders-list',
  PRODUCTS_LIST: 'products-list',
  CUSTOMERS_LIST: 'customers-list',
  TRUCKS_LIST: 'trucks-list',
  ROLES_LIST: 'roles-list',
}

export {QUERIES}
