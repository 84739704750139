/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import GoogleLogin from 'react-google-login'
import axios, {AxiosError} from 'axios'
import {Spinner} from 'react-bootstrap'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

const initialValues = {
  email: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const {saveAuth, setCurrentUser} = useAuth()

  const responseSuccess = async (response: any) => {
    setError('')
    setLoading(true)
    try {
      const {data}: any = await axios
        .post(
          `${process.env.REACT_APP_CORE_API_URL}/auth/oauth-login`,
          {
            idToken: response.tokenObj.id_token,
            userType: 'user',
            email: response.profileObj.email,
          },
          {withCredentials: true}
        )
        .catch((error: AxiosError) => {
          if (axios.isAxiosError(error)) {
            const err = error.response?.data
            setError(err.message)
            return error
          }
          setError('Something went wrong. Please try again later.')
          return error
        })
      console.log(data)
      const role = await axios.get(`${process.env.REACT_APP_CORE_API_URL}/roles/${data.roleId}`, {
        headers: {
          Authorization: `Bearer ${data.accessToken}`,
        },
      })
      saveAuth({api_token: data.accessToken, permissions: role?.data?.permissions})
      setCurrentUser(data)
    } catch (error) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }
  useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email)
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      // onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In to FuelUp Central</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          If you are having trouble signing in, please contact admin{' '}
          {/* <Link to='' className='link-primary fw-bolder'></Link> */}
        </div>
      </div>
      {/* begin::Heading */}

      {error ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{error}</div>
        </div>
      ) : (
        ''
      )}

      {/* begin::Action */}
      <div className='text-center'>
        {/* begin::Google link */}
        <GoogleLogin
          className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'
          render={(renderProps) => (
            <a
              className={
                'btn btn-flex flex-center btn-light btn-lg w-100 mb-5' + renderProps.disabled ??
                'disabled'
              }
              onClick={renderProps.onClick}
              // disabled={renderProps.disabled}
            >
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                className='h-20px me-3'
              />
              <span className='ms-2'>Sign in with Google</span>
              {loading && <Spinner className='ms-2' animation='border' />}
            </a>
          )}
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
          onSuccess={responseSuccess}
          buttonText='Continue with google'
          onFailure={responseSuccess}
        />

        {/* end::Google link */}
        {/* <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div> */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Continue with Apple
        </a> */}
      </div>
      {/* end::Action */}
    </form>
  )
}
