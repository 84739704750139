import React from 'react'
import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
      {/* <MenuItem title='Orders' to='/orders' /> */}
      {/* <MenuItem title='Support' to='/support' /> */}
      <MenuItem title='Customers' to='/apps/customer-management/customers' />
      <MenuItem title='Drivers' to='/apps/driver-management/drivers' />
      <MenuItem title='Trucks' to='/apps/truck-management/trucks' />
      {/* <MenuItem title='Layout Builder' to='/builder' /> */}
    </>
  )
}
